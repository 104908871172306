<template>
    <div class="app-container">
        <!-- 卡片视图 -->
        <el-card>
            <!-- 搜索 添加 -->
            <div class="info">
                <span>类别名称：</span>
                <el-input placeholder="请输入类别名称" class="w200" v-model="queryInfo.keyWord" clearable></el-input>
                <span>类型：</span>
                <el-select class="b1" @change="changeSelect(queryInfo.startsId)" v-model="queryInfo.startsId"
                    placeholder="请选择类型">
                    <el-option v-for="item in typeList" :key="item.id" :label="item.category" :value="item.id" />
                </el-select>

                <div style="width: 480px;">
                    <el-button type="primary" @click="getDeptList(queryInfo)">搜索</el-button>
                    <el-button type="primary" @click="addDialogVisible = true" plain>新增类别</el-button>
                </div>
            </div>

            <!-- 评分类别列表区域 -->
            <el-table :data="deptList" border stripe>
                <!-- stripe: 斑马条纹 border：边框-->
                <el-table-column label="序号" align="center" type="index" width="60"></el-table-column>
                <!-- <el-table-column label="序号" align="center" width="60">
                    <template slot-scope="scope">{{ scope.row.id }}</template>
                </el-table-column> -->
                <el-table-column prop="category" align="center" label="类别名称">
                    <template slot-scope="scope">{{ scope.row.category }}</template>
                </el-table-column>
                <el-table-column prop="starts" label="类型" align="center">
                    <template slot-scope="scope">{{ scope.row.starts == '0' ? '学生' : '班级' }}</template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini" circle
                            @click="showEditDialog(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini" circle
                            @click="removeById(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNo" :page-size="queryInfo.pageSize" layout="total, prev, pager, next, jumper"
                :total="total"></el-pagination>
        </el-card>

        <!-- 添加评分类别的对话框 -->
        <el-dialog title="添加评分类别" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
            <!-- 内容主体 -->
            <el-form :model="addForm" ref="addFormRef" :rules="addFormRules" label-width="100px">
                <el-form-item label="类别名称" prop="category">
                    <el-input v-model="addForm.category"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="starts">
                    <el-select v-model="addForm.starts" placeholder="请选择类型">
                        <el-option v-for="item in newTypeList" :key="item.id" :label="item.category" :value="item.id" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="add">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 修改评分类别的对话框 -->
        <el-dialog title="修改评分类别信息" :visible.sync="editDialogVisible" width="30%" @close="editDialogClosed">
            <!-- 内容主体 -->
            <el-form :model="editForm" ref="editFormRef" :rules="editFormRules" label-width="100px">
                <el-form-item label="类别名称" prop="category">
                    <el-input v-model="editForm.category"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="starts">
                    <el-select v-model="editForm.starts" placeholder="请选择类型">
                        <el-option v-for="item in newTypeList" :key="item.id" :label="item.category" :value="item.id" />
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editUser">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import * as api from "@/api/users.js";
import { getHttpUrl } from "@/utils/request";
export default {
    data() {
        return {

            imgBaseUrl: getHttpUrl(),

            typeList: [{ "id": "", "category": "全部" }, { "id": 0, "category": "学生" }, { "id": 1, "category": "班级" }],// 类别数据源

            newTypeList: [{ "id": 0, "category": "学生" }, { "id": 1, "category": "班级" }],// 类别数据源

            // 院系列表
            deptList: [],
            // 获取班级列表查询参数对象
            queryInfo: {
                // 当前页数
                page: 1,
                // 每页显示多少数据
                pageSize: 10,
                keyWord: '',// 院系名称
                startsId: '',// 类型（0:学生 1:班级）
            },
            gradeList: [],
            total: 0,
            // 添加班级对话框
            addDialogVisible: false,

            // 班级添加
            addForm: {
                category: "",// 名称
                starts: "",// 类型（0:学生 1:班级）
            },

            // 班级添加表单验证规则
            addFormRules: {
                category: [
                    { required: true, message: "请输类别名称", trigger: "blur" },
                    {
                        min: 2,
                        max: 50,
                        message: "院系名称的长度在2～50个字",
                        trigger: "blur"
                    }
                ],
                starts: [{ required: true, message: "类型不能为空，请选择类型！", trigger: "blur" }]
            },

            // 修改班级
            editDialogVisible: false,
            editForm: {},

            /**
             * 编辑班级表单验证
             */
            editFormRules: {
                category: [{ required: true, message: "请输入名称", trigger: "blur" }],
                name: [{ required: true, message: "请选择类型", trigger: "blur" }]
            }
        };
    },

    created() {
        let me = this;
        // 获取所有院系信息列表
        me.getDeptList();
    },

    methods: {
        /**
         * 根据查询条件分页获取评分类别接口
         */
        async getDeptList() {
            let me = this;
            let res = await api.getSysScoreClassPage(me.queryInfo);
            console.log(res, '00res');
            me.deptList = res.rows;
            me.total = res.total;
            console.log(me.deptList);
        },

        handleSizeChange(newSize) {
            let me = this;
            me.queryInfo.pageSize = newSize;
            me.getDeptList();
        },

        handleCurrentChange(newSize) {
            let me = this;
            me.queryInfo.page = newSize;
            me.getDeptList();
        },

        /**
         * 关闭新增评分类别信息提示框
         */
        addDialogClosed() {
            let me = this;
            me.$refs.addFormRef.resetFields();
        },

        /**
         * 新增评分类别信息
         */
        add() {
            let me = this;
            me.$refs.addFormRef.validate(async valid => {
                if (!valid) return;
                let res = await api.addSysScoreClass({ ...me.addForm, classscore: 100 });
                if (res.status !== 1) {
                    me.$message.error("添加评分类别失败！");
                    return;
                }
                me.$message.success("添加评分类别成功！");
                me.addDialogVisible = false;
                me.getDeptList()
            });
        },

        /**
         * 显示编辑评分类别提示框
         * @param {*} data 
         */
        async showEditDialog(data) {
            let me = this;
            let copy = Object.assign({}, data);
            me.editForm = copy;
            me.editDialogVisible = true;
        },

        /**
         * 关闭编辑评分类别提示框
         */
        editDialogClosed() {
            this.$refs.editFormRef.resetFields();
        },

        /**
         * 更新评分类别信息
         */
        editUser() {
            let me = this;
            me.$refs.editFormRef.validate(async valid => {
                if (!valid) return;
                let res = await api.updateSysScoreClass(me.editForm);
                if (res.status !== 1) {
                    me.$message.error("更新评分类别信息失败！");
                }
                me.editDialogVisible = false;
                me.$message.success("更新评分类别信息成功！");
                me.getDeptList();
            });
        },

        /**
         * 根据ID删除评分类别信息
         * 
         * @param {*} id 评分类别ID
         */
        async removeById(id) {
            const confirmResult = await this.$confirm("确定删除该评分类别?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).catch(err => err);
            // 点击确定 返回值为：confirm
            // 点击取消 返回值为： cancel
            if (confirmResult !== "confirm") {
                return this.$message.info("已取消删除");
            }
            let res = await api.removeSysScoreClass(id);
            if (res.status !== 1) return this.$message.error("删除评分类别失败！");
            this.$message.success("删除评分类别成功！");
            this.getDeptList();
        },

        /**
         * 类别下拉选改变事件回调
         */
        changeSelect() {
            let me = this;
            me.getDeptList();
        },

        /**
         * 导入成功回调
         * @param {*} response 
         */
        uploadSuccess(response) {
            let me = this;
            if (response.status === -1) {
                // me.$message.error(response.msg);
                me.$confirm(response.msg, '提示', {
                    confirmButtonText: '确定',
                    type: 'error'
                }).then(() => { }).catch(() => { });
            } else {
                me.$message({ message: '数据导入成功', type: 'success' });
                me.getDeptList();
            }
        },

        /**
         * 文件上传失败回调
         */
        uploadError(err) {
            let me = this;
            console.log("err====== ", err);
            // me.$message.error("数据导入失败！");
            me.$confirm('数据导入失败！', '提示', {
                confirmButtonText: '确定',
                type: 'error'
            }).then(() => { }).catch(() => { });
        },

        /**
         * 模板下载
         */
        downloadTemplate() {
            window.location.href = this.imgBaseUrl + "/api/import/exportTemplate?rowType=6"
        }
    }
};
</script>
  
<style lang="scss" scoped>
.grade {
    width: 100%;
}

.b1 {
    margin-left: 15px;
}

.info {
    display: flex;
    margin-bottom: 10px;

    span {
        line-height: 41px;
    }
}
</style>